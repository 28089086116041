import {useInstance} from 'react-ioc';
import {Link} from 'react-router-dom';
import type {MenuProps} from 'antd';
import {Avatar, Dropdown} from 'antd';
import logo from 'images/fts-group-logo.svg'
import bg from 'images/gradient-01.jpg'
import {observer} from 'mobx-react-lite';
import {HOME} from 'routes/routes';
import {Store} from 'store/Store';

import cls from './Header.module.scss'


export const Header = observer(() => {

    const {auth} = useInstance(Store);

    const items: MenuProps['items'] = [
        {
            key: 'logout',
            label: 'Выйти',
            onClick: () => auth.clearState(),
        },
    ]

    return (
        <div className={cls.header} style={{backgroundImage: `url(${bg})`}}>
            <div className={cls.left}>
                <Link to={HOME}>
                    <img className={cls.logo} src={logo} alt={'Logo MOOW'}/>
                </Link>
            </div>
            <div className={cls.right}>
                <Dropdown menu={{items}} trigger={['click']}>
                    <span>{auth?.data?.username} <Avatar>{auth?.data?.username.charAt(0)}</Avatar></span>
                </Dropdown>
            </div>
        </div>
    )
})