import {useNavigate} from 'react-router-dom';
import {Button} from 'antd';
import logo from 'images/fts-group-logo.svg'
import {HOME} from 'routes/routes';

import cls from './Forget.module.scss'

export const Forget = () => {

    const navigate = useNavigate()

    return (
        <div>

            <img src={logo} className={cls.logo} alt={'FTS Logo'}></img>


            <p className={cls.text}>
                Обратитесь с просьбой восстановить пароль
                к администратору по email:
                pk@skct.ru
            </p>

            <Button onClick={() => navigate(HOME)} type={'primary'}>Хорошо</Button>

        </div>
    )
}