import {gql} from '@apollo/client';
export const QUERY_ACTIVATE = gql`
    query activates($filters: ActivateFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        activates(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    Certificate_Date
                    Certificate_Number
                    Device_Type
                    Device_Name
                    Device_Number
                    FIO
                    Phone
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;