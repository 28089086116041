import {applySnapshot, types as t,} from 'mobx-state-tree';

export const initialAuthStore = {
  token: null,
  id: null,
  data: null,
};

export const AuthStore = t
  .model({
    token: t.maybeNull(t.string),
    id: t.maybeNull(t.string),
    data: t.maybeNull(t.model({
      username: t.string,
      email: t.string,
    })),
  })
  .views((self => ({
    get hasAuthToken() {
      return !!self.token;
    },
  })))
  .actions((self) => ({
    clearState: () => {
      applySnapshot(self, initialAuthStore);
    },
    setToken: (token: string | null) => {
      self.token = token;
    },
    setId: (id: string | null) => {
      self.id = id;
    },
    setTokenAndId(token: string | null, id: string | null) {
      self.token = token;
      self.id = id;
    },
    setData(data: { username: string, email: string }) {
      self.data = data;
    },
  }));
