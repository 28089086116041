import {gql} from '@apollo/client';
export const QUERY_ONLYCASH_KZ_LEADS = gql`
    query onlyCashKzLeads($filters: OnlyCashKzLeadFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        onlyCashKzLeads(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    UID
                    click_id
                    checkCodeAt
                    sendCodeAt
                    status
                    uniqPhone
                    inRandom
                    logs {
                        data {
                            id
                            attributes {
                                createdAt
                                URL
                                response
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;