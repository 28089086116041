import React from 'react';
import {Link} from 'react-router-dom';
import {Divider} from 'antd';
import cn from 'classnames'
import {observer} from 'mobx-react-lite';
import {
    ACTIVATE,
    CONSULTATION,
    DELIVERY_FILES,
    LOSS,
    ONLYCASH_KZ,
    ONLYCASH_RU,
    PARTNER,
    REFUND, SMS_FILES
} from 'routes/routes';

import OnlyCashLogo from './Logo/OnlyCash-Logo.svg'

import cls from './Home.module.scss'

export const Home = observer(() => (
    <div className={cls.home}>

        <div className={cls.group}>
            <Divider orientation={'left'} className={cls.divider}>Клиенты</Divider>
            <Link to={REFUND} className={cls.card}>Расторжения</Link>
            <Link to={CONSULTATION} className={cn([cls.card])}>Консультации</Link>
            <Link to={PARTNER} className={cn([cls.card])}>Сотрудничество</Link>
            <Link to={ACTIVATE} className={cn([cls.card])}>Активации</Link>
            <Link to={LOSS} className={cn([cls.card])}>Убытки</Link>
        </div>

        <div className={cls.group}>
            <Divider orientation={'left'} className={cls.divider}>Партнёры</Divider>
            <Link to={DELIVERY_FILES} className={cn([cls.card, cls.bgTwo])}>Рассылка сертификатов</Link>
            <Link to={SMS_FILES} className={cn([cls.card, cls.bgTwo])}>SMS Информирование</Link>
            {/*<Link to={SFTP} className={cn([cls.card, cls.bgTwo])}>SFTP</Link>*/}
        </div>

        <div className={cls.group}>
            <Divider orientation={'left'} className={cls.divider}>Проекты</Divider>
            <Link to={ONLYCASH_KZ} className={cn([cls.card, cls.bgThree])}>
                <div><img src={OnlyCashLogo} alt={'Only Cash logo'}/></div>
                <div>OnlyCash KZ</div>
            </Link>
            <Link to={ONLYCASH_RU} className={cn([cls.card, cls.bgThree])}>
                <div><img src={OnlyCashLogo} alt={'Only Cash logo'}/></div>
                <div>OnlyCash РФ</div>
            </Link>
        </div>

    </div>
))