import {CSVLink} from 'react-csv';
import { useSearchParams } from 'react-router-dom';
import {FileExcelOutlined} from '@ant-design/icons';
import { useQuery} from '@apollo/client';
import {Button, DatePicker, Input, Space, Table} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import { ENUM_ONLYCASHRU_STATUS} from '../../__generated__/global-types';
import {onlyCashRus, onlyCashRusVariables} from '../../graphql/__generated__/onlyCashRus';
import {QUERY_ONLYCASH_RU} from '../../graphql/onlycash-ru';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters } from './filter';

import cls from './OnlyCashRU.module.scss'

export const OnlyCashRU = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<onlyCashRus, onlyCashRusVariables>(QUERY_ONLYCASH_RU, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            sort: ['createdAt:DESC'],
            pagination: {
                limit: 10000
            }
        }
    })

    function displayStatus(status: ENUM_ONLYCASHRU_STATUS) {

        if (!status) return ''

        let name = ''
        switch (status) {
            case ENUM_ONLYCASHRU_STATUS.CREATED_1:
                name = '🕓 Создано';
                break;
            case ENUM_ONLYCASHRU_STATUS.SENDED_2:
                name = '📤 Отправлено';
                break;
            case ENUM_ONLYCASHRU_STATUS.SUCCESS_3:
                name = '✅ Успешный вход';
                break;
            case ENUM_ONLYCASHRU_STATUS.ERROR_4:
                name = '❌ Ошибка';
                break;
        }

        return name
    }

    const dataSource = _.map(query.data?.onlyCashRus?.data, item => ({
        ...item.attributes,
        key: item.id,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        status: displayStatus(item.attributes?.status!),
    }))

    const columns = [
        {
            title: 'Дата входа',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
        },
        {
            title: 'Телефон',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: 'Код',
            dataIndex: 'Code',
            key: 'Code',
        },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'status',
        },
    ];

    const headers = _.map(columns, item => ({label: item.title, key: item.key}))

    const onSearchChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data:any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={false} value={[filters.period[0], filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <CSVLink data={dataSource} headers={headers} filename={'OnlyCash-RU.csv'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading} />
           
        </>

    )

}