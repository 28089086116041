import React from 'react';
import { Outlet } from 'react-router-dom'
import {Header} from 'layout/Header/Header';

import cls from './Layout.module.scss';

interface ILayout {
    children?: React.ReactNode
}

export const Layout = ({children}:ILayout) => (
            <div className={cls.layout}>

                <Header/>

                <div className={cls.content}>
                    <Outlet/>
                    {children}
                </div>

            </div>
    )