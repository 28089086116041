import { type Instance } from 'mobx-state-tree';
import { persist } from 'mst-persist';

import {IRootStoreModel, RootStore} from './RootStore';

const STORE_IN_LOCAL_STORAGE_KEY = 'fts-crm-store';

export class Store {
    static create(): IRootStoreModel {
        const StoreInstance = RootStore.create({});

        persist(
            STORE_IN_LOCAL_STORAGE_KEY,
            StoreInstance,
            {
                jsonify: true,
                whitelist: [ // перечисление ключей хранилища которые надо сохранять между сессиями
                    'auth',
                ],
            },
        ).finally(() => {
            StoreInstance.setHydrated(true);
        });

        return StoreInstance;
    }
}
// eslint-disable-next-line no-redeclare
export interface Store extends Instance<typeof RootStore> {
}
