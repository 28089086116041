import {CSVLink} from 'react-csv';
import {useSearchParams} from 'react-router-dom';
import {FileExcelOutlined} from '@ant-design/icons';
import {useQuery} from '@apollo/client';
import {Button, DatePicker, Image,Input, Space, Table} from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';

import {fileUrl} from 'components/fileUrl';

import {ENUM_REFUNDREQUEST_ORDERSTATUS, ENUM_REFUNDREQUEST_TYPE} from '../../__generated__/global-types';
import {refundRequests, refundRequestsVariables} from '../../graphql/__generated__/refundRequests';
import {QUERY_REFUND} from '../../graphql/refund';

import {filtersToQuery, filtersToSearchParams, searchParamsToFilters} from './filter';

import cls from './Refund.module.scss'

export const Refund = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const filters = searchParamsToFilters(searchParams);

    const query = useQuery<refundRequests, refundRequestsVariables>(QUERY_REFUND, {
        variables: {
            filters: {
                ...filtersToQuery(filters),
            },
            pagination: {
                limit: 10000
            },
            sort: ['id:DESC']
        }
    })

    function payment(orderStatus: ENUM_REFUNDREQUEST_ORDERSTATUS, value: number) {

        if (!orderStatus) return ''

        let icon = ''
        switch (orderStatus) {
            case ENUM_REFUNDREQUEST_ORDERSTATUS.NEW:
                icon = '🕓';
                break;
            case ENUM_REFUNDREQUEST_ORDERSTATUS.WAITING:
                icon = '🕓';
                break;
            case ENUM_REFUNDREQUEST_ORDERSTATUS.DONE:
                icon = '✅';
                break;
            case ENUM_REFUNDREQUEST_ORDERSTATUS.ERROR:
                icon = '❌';
                break;
        }

        return `${icon} ${value} ₽`
    }

    const dataSource = _.map(query.data?.refundRequests?.data, item => ({
        ...item.attributes,
        key: item.id,
        vip: item.attributes?.type === ENUM_REFUNDREQUEST_TYPE.vip ? '👑' : '',
        payment: payment(item.attributes?.orderStatus!, item.attributes?.orderValue!),
        Certificate_Number: item.attributes?.requestData.Certificate_Number,
        Certificate_Date: dayjs(item.attributes?.requestData.Certificate_Date).format('DD.MM.YYYY'),
        Passport: item.attributes?.requestData.Passport,
        Bank_Name: item.attributes?.requestData.Bank_Name,
        Bank_Number: item.attributes?.requestData.Bank_Number,
        Bank_Account: item.attributes?.requestData.Bank_Account,
        Bank_Correspondent: item.attributes?.requestData.Bank_Correspondent,
        createdAt: dayjs(item.attributes?.createdAt).format('DD.MM.YYYY HH:mm'),
        file: item.attributes?.requestPdfFileUrl && fileUrl(item.attributes?.requestPdfFileUrl),
        Photo: item.attributes?.requestData.Photo,
    }))

    const columns = [
        {
            title: 'Дата заявки',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'ID',
            dataIndex: 'key',
            key: 'key',
        },
        {
            title: 'VIP',
            dataIndex: 'vip',
            key: 'vip',
        },
        {
            title: 'Оплата',
            dataIndex: 'payment',
            key: 'payment',
        },
        {
            title: 'ФИО',
            dataIndex: 'FIO',
            key: 'FIO',
        },
        {
            title: 'Телефон',
            dataIndex: 'Phone',
            key: 'Phone',
        },
        {
            title: 'Email',
            dataIndex: 'Email',
            key: 'Email',
        },
        {
            title: 'Ответ',
            dataIndex: 'template',
            key: 'template',
        },
        {
            title: 'Заявление',
            dataIndex: 'file',
            key: 'file',
            render: (url:string) => url && (<a href={url} target={'_blank'} rel={'noreferrer'}>Скачать</a>),
        },
        {
            title: 'Фото',
            dataIndex: 'Photo',
            key: 'Photo',
            render: (url:string) => url && <Image height={32} src={url}/>,
        },
    ];

    const headers = [
        {
            label: 'Дата заявки',
            key: 'createdAt',
        },
        {
            label: 'ID',
            key: 'key',
        },
        {
            label: 'VIP',
            key: 'vip',
        },
        {
            label: 'Оплата',
            key: 'payment',
        },
        {
            label: 'ФИО',
            key: 'FIO',
        },
        {
            label: 'Телефон',
            key: 'Phone',
        },
        {
            label: 'Email',
            key: 'Email',
        },
        {
            label: 'Номер сертификата',
            key: 'Certificate_Number',
        },
        {
            label: 'Дата выдачи',
            key: 'Certificate_Date',
        },
        {
            label: 'Паспорт',
            key: 'Passport',
        },
        {
            label: 'Наименование банка',
            key: 'Bank_Name',
        },
        {
            label: 'БИК',
            key: 'Bank_Number',
        },
        {
            label: 'Номер счета',
            key: 'Bank_Account',
        },
        {
            label: 'Корр счет',
            key: 'Bank_Correspondent',
        },
        {
            label: 'Ответ',
            key: 'template',
        },
        {
            label: 'Заявление',
            key: 'file',
        },
        {
            label: 'Фото',
            key: 'Photo',
        },


    ]

    const onSearchChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            search: data,
        }))
    }

    const onPeriodChange = (data: any) => {
        setSearchParams(filtersToSearchParams({
            ...filters,
            period: data,
        }))
    }

    return (
        <>
            <div className={cls.filters}>
                <div className={cls.left}>
                    <Space>
                        <DatePicker.RangePicker onChange={onPeriodChange} allowClear={false}
                                                value={[filters.period[0], filters.period[1]]} format={'DD MMM YYYY'}/>
                        <Input.Search onSearch={onSearchChange} allowClear={true}/>
                    </Space>
                </div>
                <div className={cls.right}>
                    <CSVLink data={dataSource} headers={headers} filename={'FTS-Refund.csv'}>
                        <Button icon={<FileExcelOutlined/>}>Скачать CSV</Button>
                    </CSVLink>
                </div>
            </div>
            <Table dataSource={dataSource} columns={columns} loading={query.loading}
                   expandable={{
                       expandedRowRender: (record) => (
                           <div className={cls.expanded}>
                               <ul className={cls.list}>
                                   <li><span>ФИО:</span> {record.FIO}</li>
                                   <li><span>Email:</span> {record.Email}</li>
                                   <li><span>Телефон:</span> {record.Phone}</li>
                               </ul>
                               <ul className={cls.list}>
                                   <li><span>Паспорт:</span> {record.Passport}</li>
                                   <li><span>Сертификат:</span> {record.Certificate_Number}</li>
                                   <li><span>Дата выдачи:</span> {record.Certificate_Date}</li>
                               </ul>
                               <ul className={cls.list}>
                                   <li><span>Банк:</span> {record.Bank_Name}</li>
                                   <li><span>БИК:</span> {record.Bank_Number}</li>
                                   <li><span>Номер счета:</span> {record.Bank_Account}</li>
                                   <li><span>Корр счет:</span> {record.Bank_Correspondent}</li>
                               </ul>
                           </div>
                       ),
                       rowExpandable: (record) => record.type === ENUM_REFUNDREQUEST_TYPE.free,
                   }}
            />

        </>

    )

}