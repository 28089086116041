import {gql} from '@apollo/client';
export const QUERY_ONLYCASH_RU = gql`
    query onlyCashRus($filters: OnlyCashRuFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        onlyCashRus(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    FIO
                    Phone
                    Code
                    status
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;