import React from 'react';
import {Link} from 'react-router-dom';
import cn from 'classnames';
import { SMS_FILES, SMS_INFOS} from 'routes/routes';

import cls from 'pages/Home/Home.module.scss'

export const SmsInfoMenu = () => (

    <>
        <div className={cls.home}>

            <div className={cls.group}>

            <Link to={SMS_FILES} className={cn([cls.card, cls.bgTwo])}>Реестры</Link>
            <Link to={SMS_INFOS} className={cn([cls.card, cls.bgThree])}>Услуги</Link>

            </div>

        </div>
    </>

)