import {gql} from '@apollo/client';
export const QUERY_ONLYCASH_KZ = gql`
    query onlyCashKzs($filters: OnlyCashKzFiltersInput, $pagination: PaginationArg = {}, $sort: [String] = []) {
        onlyCashKzs(filters: $filters, pagination: $pagination, sort: $sort) {
            data {
                id
                attributes {
                    createdAt
                    FIO
                    Phone
                    Code
                    status
                    lead {
                        data {
                            id
                            attributes {
                                UID
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                    page
                    pageCount
                    pageSize
                }
            }
        }
    }
`;